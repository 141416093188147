<template>
  <div>
    <h1>Timesheet</h1>
    <ul>
      <li>
        <p>First entry: worker enters. Second entry: worker leaves.</p>
        <v-form>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field label="date time" v-model="entryDateTime" data-cy="entryDateTime"></v-text-field>
              </v-col>
              <v-col>
                <v-btn @click="fakePostEntry" data-cy="simulateEntry">Simulate Entry</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>{{ responseAddEntry }}</v-col>
            </v-row>
          </v-container>
        </v-form>
      </li>
      <li>
        <v-form>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field label="from" v-model="fromDate" data-cy="fromDate"></v-text-field>
              </v-col>
              <v-col md-6>
                <v-text-field label="to" v-model="toDate" data-cy="toDate"></v-text-field>
              </v-col>
              <v-col md-6>
                <v-text-field label="worker id" v-model="workerId" data-cy="workerId"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="getTimesheet" data-cy="getTimesheet">Timesheet for current user</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>{{ responseTimesheet }}</v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="getTimesheetReport" data-cy="getTimesheetReport">Get timesheet report for everybody</v-btn>
              </v-col>
              <v-col>
                <v-btn @click="getTimesheetReportForWorker" data-cy="getTimesheetReportForWorker">Get timesheet report for
                  worker</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>{{ responseReport }}</v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="clean" data-cy="clean">Clean response</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'HomeTimesheet',

  data: () => ({
    entryDateTime: '2022-04-01T22:00:00',
    fromDate: '2022-04-01',
    toDate: '2022-04-30',
    workerId: 'granja-user',
    responseAddEntry: 'not yet',
    responseTimesheet: 'not yet',
    responseReport: 'not yet'
  }),

  methods: {
    fakePostEntry () {
      const fakeEntry = {
        dateTime: this.entryDateTime,
        location: '44.343,2.242',
        timezone: 'Europe/Madrid'
      }

      axios
        .post('/api/timesheet/entries', JSON.stringify(fakeEntry), {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((response) => {
          this.responseAddEntry = response.headers
        })
        .catch(console.error)
    },

    getTimesheet () {
      axios
        .get(`/api/timesheet/entries?from=${this.fromDate}&to=${this.toDate}`)
        .then((response) => {
          this.responseTimesheet = response.data
        })
        .catch(console.error)
    },

    getTimesheetReport () {
      axios
        .get(`/api/timesheet/report?from=${this.fromDate}&to=${this.toDate}`)
        .then((response) => {
          this.responseReport = response.data
        })
        .catch(console.error)
    },

    getTimesheetReportForWorker () {
      axios
        .get(
          `/api/timesheet/reportForWorker/${this.workerId}?from=${this.fromDate}&to=${this.toDate}`
        )
        .then((response) => {
          this.responseReport = response.data
        })
        .catch(console.error)
    },

    clean () {
      this.responseAddEntry = 'not yet'
      this.responseTimesheet = 'not yet'
      this.responseReport = 'not yet'
    }
  }
}
</script>
